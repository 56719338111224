import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextWithImage from "../components/textWithImage"
import Intro from "../components/intro"
import Footer from "../components/footer"

const Consulting = ({ data: { page } }) => (
  <Layout>
    <SEO title={page.title} lang="it" />
    <Header image={page.heroImage.url}>
      <h1>{page.title}</h1>
      <h2>{page.subtitle}</h2>
    </Header>
    <Intro title={page.title} text={page.intro} />
    {page.content.map(section => (
      <section key={section.id}>
        {section.model.apiKey === "text_with_image" && (
          <TextWithImage section={section} headingDowncase />
        )}
      </section>
    ))}
    <Footer />
  </Layout>
)

export default Consulting

export const query = graphql`
  query ConsultingQuery {
    page: datoCmsConsultingPage {
      title
      subtitle
      intro
      slug
      heroImage {
        url
      }
      content {
        ... on DatoCmsTextWithImage {
          id
          title
          text
          image {
            url
          }
          model {
            apiKey
          }
        }
      }
    }
  }
`
